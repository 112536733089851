<template>
  <drawer
    background="white"
    :position="positionDrawer"
    :size="sizeDrawer"
    @close="$emit('close')"
  >
    <div class="drawer-header">
      <wrapper class="drawer-header__wrapper">
        <div class="drawer-header__title">
          <img
            src="@/assets/review-book.svg"
            alt="review book"
          >
          <h4>
            {{ $t('questions.actions.review') }}
          </h4>
        </div>
        <s-button
          variation="tertiary"
          icon="x"
          @click="$emit('close')"
        />
      </wrapper>
    </div>
    <wrapper>
      <div class="drawer-content">
        <div v-if="video.loading">
          <h5>
            <skeleton-loader
              width="144px"
              height="24px"
              style="margin-bottom: 16px"
            />
          </h5>
          <div class="drawer-content__video">
            <skeleton-loader
              width="100%"
              height="100%"
              style="position: absolute; top: 0"
            />
          </div>
          <div class="drawer-content__book">
            <skeleton-loader
              width="190px"
              height="24px"
              style="margin-bottom: 16px"
            />
            <skeleton-loader
              width="166px"
              height="44px"
              border-radius="10px"
            />
          </div>
        </div>
        <div v-else-if="video.ready">
          <h5 class="drawer-content__title">
            {{ $t('questions.drawer.title') }}
          </h5>
          <div
            class="drawer-content__video"
            v-html="video.data.embedHtml"
          />
          <div class="drawer-content__book">
            <h5>{{ $t('questions.drawer.helper') }}</h5>
            <s-button
              size="large"
              variation="secondary"
              icon-left="book-open"
              :loading="pageflip.loading"
              :disabled="pageflip.loading"
              @click="fetchPageFlip"
            >
              {{ $t('questions.drawer.getBook') }}
            </s-button>
          </div>
        </div>
      </div>
      <EmptyState
        v-if="!video.loading && video.error"
        image="video-not-found"
        :title="$t('questions.drawer.empty.title.review')"
        :description="
          $t('questions.drawer.empty.description.review')
        "
      >
        <s-button
          v-if="video.error"
          icon-left="refresh-ccw"
          @click="$emit('refresh')"
        >
          {{ $t('commons.tryAgain') }}
        </s-button>
      </EmptyState>
    </wrapper>
    <div
      v-if="pageflip.data.contentPath !== null"
      v-show="false"
    >
      <s-button
        ref="pageFlipButton"
        target="_blank"
        :href="pageflip.data.contentPath"
      />
    </div>
  </drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import EmptyState from '@/components/EmptyState'

export default {
  name: 'QuestionHelper',
  components: {
    EmptyState,
  },
  computed: {
    ...mapGetters([
      'video',
      'pageflip',
    ]),
    positionDrawer() {
      return this.$mq === 'small'
        ? 'bottom'
        : 'right'
    },
    sizeDrawer() {
      switch (this.$mq) {
        case 'x_large':
          return '490px'
        case 'small':
          return null
        default:
          return '50%'
      }
    },
  },
  methods: {
    ...mapActions([
      'getPageFlip',
    ]),
    async fetchPageFlip() {
      const code = this.$route.params.questionnaireCode
      await this.getPageFlip(code)
      if (
        this.pageflip.ready !== undefined
        && this.pageflip.data.contentPath
      ) {
        this.$refs.pageFlipButton.$el.click()
      } else {
        this.$toasted.global.error({
          title: this.$t('questions.drawer.pageflip.toast.error.message'),
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.drawer
  transition: transform $speed-fast ease-in-out !important
  height: initial
  max-height: 100%
  padding-bottom: $size-l

  +mq-s--mf
    padding-bottom: 132px

  &.--position-bottom
    border-radius: $size-s $size-s 0 0

  &-header
    padding: $size-s 0
    border-bottom: 1px solid $color-ink-lightest
    background: $color-white
    position: sticky
    top: 0
    z-index: 100

    &__wrapper
      +flex-space-between

      +mq-xl--mf
        padding:
          left: $size-l
          right: $size-l

    &__title
      +flex-center-start

      img
        margin-right: $size-s

  &-content
    padding: $size-s 0

    +mq-xl--mf
      padding:
        left: $size-l
        right: $size-l

    &__title
      margin-bottom: $size-s

    &__video
      +proportional-video

      ::v-deep iframe
        +proportional-video-iframe

    &__book
      padding: $size-xl 0 $size-s

      +mq-xs
        +flex-center
        flex-flow: column

      h5
        margin-bottom: $size-s
</style>
