<template>
  <div>
    <box
      v-for="option in options"
      :key="option.id"
      class="question-options"
      elevation-hover="2"
      :class="{
        '--active': isOptionMarked(option.id),
        '--correct': isOptionCorrect(option.id),
        '--incorrect': isOptionIncorrect(option.id),
        '--disabled': showFeedback
      }"
      @click.native="markOption(option.id)"
    >
      <RichTextViewer :text="option.description" />
      <icon
        v-if="isOptionCorrect(option.id)"
        type="check"
        :stroke="$tokens.color_success"
      />
      <icon
        v-if="isOptionIncorrect(option.id)"
        type="x"
        :stroke="$tokens.color_danger"
      />
    </box>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RichTextViewer from '@/components/RichTextViewer'

export default {
  name: 'QuestionOptions',
  components: {
    RichTextViewer,
  },
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    showFeedback: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      markedOptionId: false,
    }
  },
  computed: {
    ...mapGetters([
      'answer',
    ]),
  },
  created() {
    if (Object.values(this.answer.data).length) {
      this.markedOptionId = this.answer.data.markedOption
    }
  },
  methods: {
    markOption(optionId) {
      const selectedOption = parseInt(optionId, 10)

      if (this.markedOptionId === selectedOption) {
        this.markedOptionId = null
      } else {
        this.markedOptionId = selectedOption
      }

      this.$emit('optionMarked', this.markedOptionId)
    },
    isOptionMarked(optionId) {
      const option = this.answer.data.markedOption || this.markedOptionId

      return option === parseInt(optionId, 10)
    },
    isOptionCorrect(optionId) {
      if (!this.showFeedback) {
        return null
      }

      return this.answer.data.correctOption === parseInt(optionId, 10)
    },
    isOptionIncorrect(optionId) {
      if (!this.showFeedback) {
        return null
      }

      return this.answer.data.correctOption
        !== parseInt(optionId, 10)
        && this.isOptionMarked(optionId)
    },
  },
}
</script>

<style lang="sass" scoped>
.question
  &-options
    border: 1px solid rgba($color-ink, .15)
    cursor: pointer
    padding: 0 24px !important
    +space-stack($size-s)
    +flex-space-between

    &.--active
      border-color: $color-primary
      box-shadow: 0 0 0 2px $color-primary

    &.--correct
      border-color: $color-success-light
      box-shadow: 0 0 0 2px $color-success-light

    &.--incorrect
      border-color: $color-danger-light
      box-shadow: 0 0 0 2px $color-danger-light

    &.--disabled
      pointer-events: none

    .feather
      flex-shrink: 0

    ::v-deep img
      width: auto
      max-width: 100%
      height: auto
      display: inline-block

    ::v-deep p
      font-size: 18px
      margin: 20px 0
      line-height: 1.5
      letter-spacing: 0.25px
</style>
