<template>
  <div class="assignment">
    <div
      v-if="!questions.loading && !questions.ready"
      class="assignment__loading"
    >
      <Spinner size="large" />
    </div>
    <template v-else>
      <QuestionBar
        v-if="!error"
        :light-mode="questionBarLightMode"
        :loading="loading"
        :active-question-index="activeQuestionIndex"
        :custom-style="subjectStyleHeader"
        @close="close"
        @navigate="navigateTo"
      />
      <StatusConnection />
      <div
        :class="[
          'assignment__content',
          {'--center': error},
        ]"
      >
        <QuestionSkeleton v-if="loading && !error" />
        <template v-else-if="!error">
          <header class="assignment__content__title">
            <h5 class="assignment__content__title__text">
              {{
                $t('questions.questionNumber', {
                  current: activeQuestionIndex + 1
                })
              }}
            </h5>
          </header>
          <QuestionContent
            @selectOption="selectOption"
          />
          <div
            class="assignment__content__report-question-problem__wrapper"
          >
            <s-button
              v-if="!userReportedProblem"
              class="assignment__content__report-question-problem"
              icon-left="flag"
              variation="tertiary"
              @click="toggleReportAProblem"
            >
              {{ $t('questions.reportAProblem.buttonLabel') }}
            </s-button>
            <s-badge
              v-else-if="userReportedProblem"
              class="assignment__content__report-question-problem"
              icon-left="flag"
              variation="warning"
            >
              {{ $t('questions.reportAProblem.youReportedAProblem') }}
            </s-badge>
          </div>
          <QuestionFooter
            :selected-option="selectedOption"
            :question-id="question.data.id"
            :assignment-code="assignmentCode"
            :questionnaire-type="question.data.assignment.questionnaireType"
            :can-be-finished="canBeFinished"
            :has-next="hasNext"
            @answer="handleSendAnswer"
            @next-question="nextQuestion"
            @report="report"
          />
        </template>
        <EmptyState
          v-else
          :image="emptyState.image"
          :title="emptyState.title"
          :description="emptyState.description"
        >
          <s-button
            icon-left="arrow-left"
            @click="close"
          >
            {{ $t('commons.back') }}
          </s-button>
        </EmptyState>
        <ReportProblem
          v-if="onReportProblem"
          @report-succeed="fetchQuestions"
          @close="toggleReportAProblem"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { QUESTION_STATES } from '@/service/questions'
import { isNotEmpty } from '@/utils/arrays'
import mediaQueries from '@/mixins/mediaQueries'
import subject from '@/mixins/subject'
import subjectStyleHeader from '@/mixins/subjectStyleHeader'

import EmptyState from '@/components/EmptyState'
import Spinner from '@/components/Spinner'
import ReportProblem from '@/components/ReportProblem/ReportProblem'
import StatusConnection from '@/components/StatusConnection/StatusConnection'

import QuestionBar from './partials/QuestionBar'
import QuestionContent from './partials/QuestionContent'
import QuestionFooter from './partials/QuestionFooter'
import QuestionSkeleton from './partials/QuestionSkeleton'

export default {
  name: 'Assignment',
  components: {
    EmptyState,
    Spinner,
    ReportProblem,
    StatusConnection,
    QuestionBar,
    QuestionContent,
    QuestionFooter,
    QuestionSkeleton,
  },
  mixins: [ mediaQueries, subject, subjectStyleHeader ],
  props: {
    questionBarLightMode: Boolean,
    lectureId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: null,
      activeQuestionIndex: -1,
      onReportProblem: false,
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'questions',
      'question',
      'answer',
    ]),
    error() {
      return Boolean(
        this.question.error
        || this.questions.error
        || this.answer.error
      )
    },
    hasSubjectStyleHeader() {
      return isNotEmpty(Object.values(this.subjectStyleHeader))
    },
    questionnaireNotFound() {
      return [
        this.question.error?.status,
        this.questions.error?.status,
      ].includes(404)
      || [
        this.question.error?.status,
        this.answer.error?.status,
      ].includes(422)
    },
    loading() {
      return this.question.loading || this.questions.loading
    },
    assignmentCode() {
      return this.question.data.assignment?.code || ''
    },
    hasNext() {
      return this.activeQuestionIndex <= this.questions.data?.length - 2
    },
    canBeFinished() {
      if (this.questions.ready) {
        return this.questions.data.every((question) => (
          question.status !== QUESTION_STATES.NOT_ANSWERED
        ))
      }

      return false
    },
    handleExtraActivityCategory() {
      return this.$route.params.context === 'year' ? this.$track.category.yearlyEnemPractice : this.$track.category.difficultyEnemPractice
    },
    emptyState() {
      if (this.questionnaireNotFound) {
        return {
          image: 'empty-activities',
          title: this.$t('questions.empty.404.title'),
          description: this.$t('questions.empty.404.description'),
        }
      }

      return {
        image: 'question-error',
        title: this.$t('questions.empty.error.title'),
        description: this.$t('questions.empty.error.description'),
      }
    },
    userReportedProblem() {
      return this.question.data.complaint?.creatorId === this.user.id
    },
  },
  created() {
    this.fetchQuestions()
  },
  methods: {
    ...mapActions([
      'getQuestions',
      'setQuestions',
      'getQuestion',
      'sendAnswer',
      'clearComplaints',
    ]),
    async fetchQuestions() {
      try {
        await this.getQuestion({ ...this.$route.params })
        if (this.question.data.assignment.status === 'finished') {
          this.report()
        } else {
          await this.getQuestions({ ...this.$route.params })

          this.activeQuestionIndex = this.questions.data.findIndex(
            (question) => question.id === this.question.data.id
          )
        }
      } catch (error) {
        console.error(error)
      }
    },
    close() {
      if (this.$route.name === 'lecture-question') {
        this.$router.push({
          name: 'lecture-assignments',
        })
      }

      if (this.$route.name === 'extra-activity-question') {
        if (!this.canBeFinished) {
          this.$trackEvent({
            category: this.$track.category.extraChapterBookActivities,
            action: this.$track.action.pausedChapter,
          })
        }
        if (this.canBeFinished) {
          this.$trackEvent({
            category: this.$track.category.extraChapterBookActivities,
            action: this.$track.action.finishedChapter,
          })
        }
        this.$router.push({
          name: 'extra-activity-assignments',
          query: this.$route.query,
        })
      }
    },
    navigateTo(index) {
      this.selectedOption = null

      if (this.activeQuestionIndex !== index) {
        this.activeQuestionIndex = index
        this.getQuestion({
          assignmentCode: this.assignmentCode,
          questionId: this.questions.data[index].id,
        })
      }
    },
    nextQuestion() {
      this.selectedOption = null

      if (this.hasNext) {
        this.activeQuestionIndex += 1
        this.getQuestion({
          assignmentCode: this.assignmentCode,
          questionId: this.questions.data[this.activeQuestionIndex].id,
        })
      }
    },
    selectOption(selectedOption) {
      this.selectedOption = selectedOption
    },
    async handleSendAnswer() {
      try {
        await this.sendAnswer({
          questionId: this.question.data.id,
          markedOption: parseInt(JSON.parse(this.selectedOption), 10),
          assignmentCode: this.assignmentCode,
        })

        const index = this.questions.data.findIndex((question) => (
          question.id === this.answer.data.questionId
        ))

        const question = { ...this.questions.data[index] }
        question.status = (
          this.answer.data.correctOption === this.answer.data.markedOption)
          ? QUESTION_STATES.CORRECT
          : QUESTION_STATES.WRONG

        const questions = [
          ...this.questions.data.slice(0, index),
          question,
          ...this.questions.data.slice(index + 1),
        ]

        this.setQuestions({ questions })
      } catch (error) {
        console.error(error)
      }
    },
    report() {
      const { query } = this.$route
      const params = {
        assignmentCode: this.assignmentCode,
      }

      this.trackEvent()

      if (this.$route.params.lectureId) {
        this.$router.replace({
          name: 'lecture-report',
          params,
        })
      } else if (this.$route.params.extraActivityId) {
        this.$router.replace({
          name: 'extra-activity-report',
          params,
          query,
        })
      }
    },
    toggleReportAProblem() {
      setTimeout(() => { this.onReportProblem = !this.onReportProblem }, 100)
    },
    trackEvent() {
      const isProposedActivity = this.question.data
        .assignment.questionnaireType === 'proposed_activity'
      const { title, subtitle } = this.question.data.assignment

      if (this.canBeFinished && !this.$route.name === 'extra-activity-question') {
        this.$trackEvent({
          category: this.$track.category.extraChapterBookActivities,
          action: this.$track.action.finishedChapter,
        })
      }

      if (this.canBeFinished && this.$route.name === 'extra-activity-question') {
        this.$trackEvent({
          category: this.handleExtraActivityCategory,
          action: this.$track.action.finishedSubject,
          label: `${title} - ${subtitle}`,
        })
      }

      if (isProposedActivity) {
        this.$trackEvent({
          category: this.$track.category.proposedQuestions,
          action: this.$track.action.finish,
          label: this.subject.data.name,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.assignment {
  background: $color-white;
  width: 100%;
  min-height: calc(100vh - #{$menu-height});

  &__loading {
    @include cover;
    @include flex-center;
  }

  &__content {
    min-height: calc(100vh - #{$menu-height});
    padding: $size-m $size-l $end-space;

    &.--center {
      @include flex-column-center;
    }

    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $size-s;
      max-width: $question-max-width;
      margin: 0 auto $size-s auto;

      &__text {
        flex-grow: 1;
      }
    }

    &__report-question-problem__wrapper {
      width: 100%;
      max-width: $question-max-width;
      margin: $size-xl auto;
    }
  }
}
</style>
