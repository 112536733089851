<template>
  <div>
    <footer
      :class="[
        { '--active': selectedOption !== null},
        'question-footer', feedbackClass
      ]"
    >
      <wrapper class="question-footer__wrapper">
        <div
          v-if="showFeedback"
          class="question-footer__feedback"
        >
          <span
            :class="[ 'question-footer__feedback__emoji', feedbackClass ]"
          >
            <img
              v-if="isCorrect"
              src="@/assets/emoji-right.png"
              alt="icon right"
            >
            <img
              v-else
              src="@/assets/emoji-wrong.png"
              alt="icon wrong"
            >
          </span>
          <div
            :class="[ 'question-footer__feedback__text', feedbackClass ]"
          >
            <h4>
              {{
                isCorrect
                  ? $t('questions.actions.correct')
                  : $t('questions.actions.incorrect')
              }}
            </h4>
            <p>
              {{
                isCorrect
                  ? $t('questions.actions.congratulation')
                  : $t('questions.actions.ops')
              }}
            </p>
          </div>
        </div>
        <div
          v-else
          class="question-footer__help"
        >
          <s-button
            v-if="hasReview"
            icon-left="book-open"
            variation="tertiary"
            :disabled="answer.loading"
            @click="handleToggleDrawer"
          >
            {{ $t('questions.actions.review') }}
          </s-button>
        </div>
        <s-button
          class="question-footer__button --report"
          variation="secondary"
          icon-left="bar-chart"
          :size="mq_xs && !showFeedback ? 'medium' : 'large'"
          :disabled="!canBeFinished"
          :loading="loading"
          @click="actionFooter"
        >
          {{ $t('commons.finish') }}
        </s-button>
        <s-button
          v-if="!showFeedback"
          class="question-footer__button --answer"
          size="large"
          :disabled="selectedOption === null"
          :loading="answer.loading"
          @click="$emit('answer')"
        >
          {{ $t('questions.actions.answer') }}
        </s-button>
        <s-button
          v-else-if="hasNext"
          class="question-footer__button --next"
          size="large"
          icon-right="arrow-right"
          variation="secondary"
          :loading="loading"
          @click="$emit('next-question')"
        >
          {{ $t('commons.next.female') }}
        </s-button>
      </wrapper>
    </footer>
    <QuestionHelper
      v-if="toggleDrawer"
      @close="handleToggleDrawer"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import mediaQueries from '@/mixins/mediaQueries'
import QuestionHelper from './QuestionHelper'

export default {
  name: 'QuestionFooter',
  components: {
    QuestionHelper,
  },
  mixins: [
    mediaQueries,
  ],
  props: {
    selectedOption: {
      type: Number,
      default: null,
    },
    questionId: {
      type: String,
      required: true,
      default: '',
    },
    assignmentCode: {
      type: String,
      required: true,
      default: '',
    },
    questionnaireType: {
      type: String,
      required: true,
      default: '',
    },
    canBeFinished: {
      type: Boolean,
      required: true,
      default: false,
    },
    hasNext: {
      type: Boolean,
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      toggleDrawer: false,
    }
  },
  computed: {
    ...mapGetters([
      'answer',
      'question',
    ]),
    positionDrawer() {
      return [ 'xsmall', 'small' ].includes(this.$mq)
        ? 'bottom'
        : 'right'
    },
    sizeDrawer() {
      return [ 'xsmall', 'small' ].includes(this.$mq) ? '50vh' : '50%'
    },
    showFeedback() {
      return this.answer.ready
    },
    isCorrect() {
      return this.answer.data.correct
    },
    feedbackClass() {
      return {
        '--right': this.showFeedback && this.isCorrect,
        '--wrong': this.showFeedback && !this.isCorrect,
      }
    },
    hasReview() {
      return this.questionnaireType === 'high_school'
    },
  },
  methods: {
    ...mapActions([
      'getVideo',
    ]),
    actionFooter() {
      this.$trackEvent({
        category: this.$track.category.extraChapterBookActivities,
        action: this.$track.action.seeReport,
      })
      this.$emit('report')
    },
    handleToggleDrawer() {
      this.toggleDrawer = !this.toggleDrawer
      if (this.toggleDrawer) {
        this.$trackEvent({
          category: this.$track.category.extraChapterBookActivities,
          action: this.$track.action.reviewChapter,
        })

        setTimeout(() => { this.toggleDrawer = true }, 100)
        this.getVideo(this.$route.params.questionnaireCode)
      }
    },
    refresh() {
      this.getVideo(this.$route.params.questionnaireCode)
    },
  },
}
</script>

<style lang="sass" scoped>
.question-footer
  position: fixed
  bottom: 0
  left: 0
  right: 0
  background: $color-white
  box-shadow: 0 0 8px rgba($color-ink, .25)
  padding: $size-s
  transition: background $speed-fast, transform $speed-fast

  +mq-xs
    transform: translateY(62px)

  &-enter,
  &-leave-to
    transform: translateY(100%)

  &.--zindex
    +mq-s--mf
      z-index: 101

  &.--active
    +mq-xs
      transform: translateY(0)

  &.--wrong,
  &.--right
    box-shadow: none

    +mq-xs
      transform: translateY(0)

  &.--right
    background: tint-color($color-success-lightest, 25%)

  &.--wrong
    background: tint-color($color-danger-lightest, 25%)

  &__wrapper
    +flex-space-between
    flex-wrap: wrap
    +mq-xs
      max-width: 100%

    +mq-s--mf
      +flex-space-between

  &__help
    flex-grow: 1

  &__button.--answer
    flex:
      grow: 1
      basis: 100%
    margin-top: $size-s

    +mq-s--mf
      flex:
        basis: auto
        grow: 0
      width: 240px
      margin:
        top: 0
        left: $size-s

  &__button.--next
    +mq-s--mf
      margin-left: $size-s

  &__feedback
    flex:
      basis: 100%
      grow: 1
    +flex-center-start
    margin-bottom: $size-m

    +mq-m--mf
      margin-bottom: 0
      flex-basis: auto

    &__emoji
      width: $size-xl
      height: $size-xl
      flex-shrink: 0
      border-radius: 100%
      margin-right: $size-s
      +flex-center

      &.--right
        background: transparentize($color-success-lighter, .5)

      &.--wrong
        background: transparentize($color-danger-lighter, .5)

      img
        display: block
        width: 36px
        height: 36px

    &__text
      &.--right
        h4,
        p
          color: $color-success-dark

      &.--wrong
        h4,
        p
          color: $color-danger-dark

    &__content
      +flex-center

    &__finish-text
      margin: $size-xs
</style>
