<template>
  <div class="question-content">
    <RichTextViewer
      class="question-content__html"
      :text="question.data.statement"
    />
    <QuestionOptions
      :options="question.data.options"
      :show-feedback="answer.ready"
      @optionMarked="optionMarked"
    />
    <div
      v-if="answer.ready && answer.data.explanation"
      class="question-content__explanation"
    >
      <div class="question-content__explanation__header">
        <icon type="zap" />
        <h4>{{ $t('commons.explanation') }}</h4>
      </div>
      <div
        v-if="answer.data.video"
        class="question-content__explanation__video"
        v-html="answer.data.video.embedHtml"
      />
      <RichTextViewer :text="answer.data.explanation" />
    </div>
    <viewer
      v-show="false"
      ref="viewer"
      :images="activeImage"
    >
      <template #default="{images}">
        <img
          v-for="src in images"
          :key="src"
          class="image"
          :src="src"
        >
      </template>
    </viewer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RichTextViewer from '@/components/RichTextViewer'
import QuestionOptions from './QuestionOptions'

export default {
  name: 'QuestionContent',
  components: {
    RichTextViewer,
    QuestionOptions,
  },
  data() {
    return {
      selectedOption: null,
      activeImage: [],
    }
  },
  computed: {
    ...mapGetters([
      'answer',
      'question',
    ]),
    assignmentCode() {
      return this.question.data.assignment
        ? this.question.data.assignment.code
        : ''
    },
    options() {
      return this.question.data.options
        ? this.question.data.options
        : []
    },
  },
  mounted() {
    this.setupImageHandlers()
  },
  methods: {
    optionMarked(value) {
      this.selectedOption = value
      this.$emit('selectOption', this.selectedOption)
    },
    setupImageHandlers() {
      document.querySelectorAll('.question-content img').forEach(
        (img) => {
          img.addEventListener('click', this.imageClickHandler)
        }
      )
    },
    imageClickHandler(event) {
      this.activeImage = [ event.target.getAttribute('src') ]
      this.$refs.viewer.$viewer.show()
    },
  },
}
</script>

<style lang="sass" src="@/styles/viewer.sass" />

<style lang="sass" scoped>
.question-content
  width: 100%
  max-width: $question-max-width
  margin: 0 auto

  ::v-deep img:hover
    cursor: zoom-in

  &__html,
  &__explanation
    padding-bottom: $size-m

    ::v-deep img
      +embedded-image

    ::v-deep p
      font-size: $font-size-heading-6 !important
      +embedded-paragraph

  &__explanation
    margin-top: $size-xl

    &__header
      +flex-center-start
      color: $color-primary
      border-bottom: 2px solid $color-primary
      padding-bottom: $size-xs

      h4
        margin-left: $size-xs

    &__video
      +proportional-video
      margin-top: $size-m

      ::v-deep iframe
        +proportional-video-iframe
</style>
