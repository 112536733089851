<template>
  <transition name="fade">
    <div
      v-if="showStatusConnection"
      :class="setClass"
    >
      <p v-if="isOnline">
        <icon type="wifi" />
        {{ $t('questions.online') }}
      </p>
      <p v-else>
        <icon type="wifi-off" />
        {{ $t('questions.offline') }}
      </p>
    </div>
  </transition>
</template>

<script>
const EVENTS = [ 'online', 'offline' ]

export default {
  name: 'StatusConnection',
  data() {
    return {
      isOnline: navigator.onLine || false,
      showStatusConnection: false,
    }
  },
  computed: {
    setClass() {
      return {
        'status-connection': true,
        'status-connection--offline': !this.isOnline,
      }
    },
  },
  created() {
    EVENTS.forEach((event) => {
      window.addEventListener(event, this.updateOnlineStatus)
    })
  },
  beforeDestroy() {
    EVENTS.forEach((event) => {
      window.removeEventListener(event, this.updateOnlineStatus)
    })
  },
  methods: {
    updateOnlineStatus() {
      if (!navigator.onLine) {
        this.showStatusConnection = true
      }

      if (this.showStatusConnection && navigator.onLine) {
        setTimeout(() => {
          this.showStatusConnection = !navigator.onLine
        }, 4000)
      }

      this.isOnline = navigator.onLine || false
    },
  },
}
</script>

<style lang="sass" scoped>
$online: #18C57C
$offline: #FF4646
$color-font: #F0F0F2

.status-connection
  width: 100%
  background: $online
  color: $color-white
  padding: 16px
  text-align: center

  +mq-s
    text-align: left

  &--offline
    background: $offline

  p
    font-size: 18px
    color: $color-font
    +flex-center

    i
      margin-right: 16px
      min-width: 30px
</style>
