<template>
  <div class="question-content">
    <div
      class="question-content__html"
    >
      <skeleton-loader
        width="40%"
        height="18px"
      />
      <skeleton-loader
        width="90%"
        height="18px"
      />
      <skeleton-loader
        width="70%"
        height="18px"
      />
      <skeleton-loader
        width="50%"
        height="18px"
      />
    </div>
    <box
      v-for="index in 4"
      :key="index"
      class="question-content__options"
      elevation-hover="2"
    >
      <skeleton-loader
        width="40%"
        height="18px"
      />
    </box>
    <footer class="question-footer">
      <wrapper class="question-footer__wrapper">
        <div class="question-footer__help">
          <skeleton-loader
            width="140px"
            height="22px"
          />
        </div>
        <div class="question-footer__actions">
          <skeleton-loader
            width="100%"
            height="44px"
            border-radius="10px"
          />
        </div>
      </wrapper>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'QuestionSkeleton',
}
</script>

<style lang="sass" scoped>
.question-content
  width: 100%
  max-width: $question-max-width
  margin: 0 auto

  &__html
    margin-bottom: 50px

    .sas-skeleton-loader:not(:last-child)
      margin-bottom: 20px

  &__options
    margin-bottom: 10px
    border: 1px solid rgba($color-ink, .15)

  .question-footer
    position: fixed
    bottom: 0
    left: 0
    right: 0
    background: $color-white
    box-shadow: 0 0 8px rgba($color-ink, .25)
    padding: 16px

    &__wrapper
      +mq-xs
        max-width: 100%

      +mq-s--mf
        +flex-space-between

    &__actions
      width: 100%
      max-width: 248px

      +mq-xs
        display: none

    &__help
      +flex-space-between

      .sas-skeleton-loader:not(:last-child)
        margin-right: 16px
</style>
